import { BrandColor, UIThemeColors } from '@wearemojo/ui-constants';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import Text from './Text';
import { ContentVariables, InlineContent } from './utils/types';

// TODO: possibly replace `Tag` with this component
// TODO: change BrandColor to use tokens

type Props = {
	title: InlineContent;
	background: keyof typeof BrandColor;
	textColor?: keyof UIThemeColors;
	contentVariables?: ContentVariables;
};

const Chip = ({ title, background, textColor, contentVariables }: Props) => {
	const { styles } = useStyles(stylesheet, { background });

	return (
		<View style={styles.container}>
			<Text.CMSContent
				variant="heading_xxs"
				value={title}
				themeColor={textColor}
				style={styles.text}
				contentVariables={contentVariables}
			/>
		</View>
	);
};

const brandColorKeys = Object.keys(BrandColor) as (keyof typeof BrandColor)[];
const chipBackgroundStyles = brandColorKeys.reduce(
	(acc, key) => {
		acc[key] = {
			backgroundColor: BrandColor[key],
		};
		return acc;
	},
	{} as Record<keyof typeof BrandColor, { backgroundColor: string }>,
);

const stylesheet = createStyleSheet(({ spacing, radius }) => ({
	container: {
		paddingVertical: spacing.xs,
		paddingHorizontal: spacing.lg,
		borderRadius: radius.full,
		alignSelf: 'flex-start',
		variants: {
			background: chipBackgroundStyles,
		},
	},
	text: {
		textTransform: 'uppercase',
	},
}));

export default Chip;
