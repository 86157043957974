import { maxWidth, Spacing } from '@wearemojo/ui-constants';
import React, { ReactNode, useContext } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import useUIContext from './hooks/useUIContext';
import UIContext from './UIContext';
import { UIFormFactor } from './utils/types';

type Props = {
	children: ReactNode;
	noMax?: boolean;
	noGutter?: boolean;
	containerStyle?: StyleProp<ViewStyle>;
	style?: StyleProp<ViewStyle>;
};

const Belt = ({
	children,
	containerStyle,
	style,
	noGutter = false,
	noMax = false,
}: Props) => {
	const { formFactor } = useContext(UIContext);
	const isDesktop = formFactor === UIFormFactor.desktop;
	return (
		<View style={[styles.root, containerStyle]}>
			<View
				style={[
					styles.main,
					isDesktop && styles.mainDesktop,
					noGutter && styles.mainNoGutter,
					noMax && styles.mainNoMax,
					style,
				]}
			>
				{children}
			</View>
		</View>
	);
};

const WithDesktopRow = ({ children }: Props) => {
	const { isDesktop } = useUIContext();
	return (
		<Belt>
			<View style={[styles.desktopRow, isDesktop && styles.desktopRowActive]}>
				{children}
			</View>
		</Belt>
	);
};

Belt.WithDesktopRow = WithDesktopRow;

export const padding = {
	[UIFormFactor.mobile]: Spacing.large,
	[UIFormFactor.desktop]: Spacing.dsDeviation__40,
};

const styles = StyleSheet.create({
	root: {
		alignItems: 'center',
		justifyContent: 'center',
	},
	main: {
		width: '100%',
		alignItems: 'center',
		paddingHorizontal: padding.mobile,
		maxWidth: maxWidth + padding.desktop * 2,
	},
	mainDesktop: {
		paddingHorizontal: padding.desktop,
	},
	mainNoGutter: {
		maxWidth,
		padding: 0,
	},
	mainNoMax: {
		maxWidth: 'auto',
	},
	desktopRow: {
		width: '100%',
		alignItems: 'center',
	},
	desktopRowActive: {
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'center',
	},
});

export default Belt;
