import { BrandColor } from '@wearemojo/ui-constants';
import { ComponentType } from 'react';
import { AccessibilityRole, ActivityIndicator, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import PressableOpacity from './PressableOpacity';
import Text from './Text';
import { MojoIcon } from './utils/types';

// TODO: replace all BrandColors with tokens

export type Props = {
	type?: ButtonType;
	title?: string;
	icon?: ComponentType<MojoIcon>;
	iconPosition?: 'left' | 'right';
	onPress?: () => void;
	loading?: boolean;
	disabled?: boolean;
	size?: 'full' | 'fixed' | 'inline_start' | 'inline_end' | 'inline_center';
} & ButtonLinkProps;

type ButtonLinkProps = {
	href?: string;
	accessibilityRole?: AccessibilityRole;
	hrefAttrs?: { target?: string };
};

type ButtonType = 'primary' | 'secondary' | 'warning';

export const buttonWebDataSelector = 'mojo-ui-button';

const dataAttrs = {
	dataSet: {
		[buttonWebDataSelector]: '', // Used as hook for CSS selector on web
	},
};

export default ({
	type = 'primary',
	title,
	disabled = false,
	onPress,
	loading = false,
	size = 'full',
	icon: Icon,
	iconPosition = 'left',
	...props
}: Props) => {
	const {
		styles,
		theme: { iconSize },
	} = useStyles(stylesheet, { type, size });

	const isDisabled = disabled;
	const canPress = !isDisabled && !loading;

	return (
		<PressableOpacity
			style={[styles.root, isDisabled && styles.rootDisabled]}
			disabled={isDisabled}
			onPress={canPress ? onPress : undefined}
			{...dataAttrs}
			{...props}
		>
			<View style={styles.container}>
				{!loading && (
					<>
						{Icon && iconPosition === 'left' && (
							<Icon size={iconSize.xs} color={getIconColor(type, disabled)} />
						)}
						{title && (
							<Text
								numberOfLines={2}
								style={[
									styles.buttonTitle,
									isDisabled && styles.buttonTitleDisabled,
								]}
								variant="action"
							>
								{title}
							</Text>
						)}
						{Icon && iconPosition === 'right' && (
							<Icon size={iconSize.xs} color={getIconColor(type, disabled)} />
						)}
					</>
				)}
				{loading && (
					<ActivityIndicator size="small" color={buttonContentColor[type]} />
				)}
			</View>
		</PressableOpacity>
	);
};

const getIconColor = (type: ButtonType, disabled: boolean) => {
	if (disabled) return buttonContentDisabledColor[type];
	return buttonContentColor[type];
};

const buttonContentColor = {
	primary: BrandColor.black,
	secondary: BrandColor.white,
	warning: BrandColor.white,
} satisfies Record<ButtonType, BrandColor>;

const buttonContentDisabledColor = {
	primary: BrandColor.neutral_300,
	secondary: BrandColor.neutral_400,
	warning: BrandColor.neutral_300,
} satisfies Record<ButtonType, BrandColor>;

const BUTTON_HEIGHT = 56;
const BORDER_RADIUS = BUTTON_HEIGHT / 2;
const BUTTON_FIXED_WIDTH = 280;

const stylesheet = createStyleSheet(({ spacing }) => ({
	root: {
		borderRadius: BORDER_RADIUS,
		minHeight: BUTTON_HEIGHT,
		borderWidth: 1,
		borderColor: 'transparent',
		variants: {
			size: {
				full: {
					width: '100%',
				},
				fixed: {
					width: BUTTON_FIXED_WIDTH,
				},
				inline_start: {
					alignSelf: 'flex-start',
				},
				inline_end: {
					alignSelf: 'flex-end',
				},
				inline_center: {
					alignSelf: 'center',
				},
			},
			type: {
				primary: {
					backgroundColor: BrandColor.primary_yellow,
				},
				secondary: {
					backgroundColor: BrandColor.neutral_800,
					borderColor: BrandColor.neutral_400,
				},
				warning: {
					backgroundColor: BrandColor.red,
				},
			},
		},
	},
	rootDisabled: {
		variants: {
			type: {
				primary: {
					backgroundColor: BrandColor.neutral_400,
				},
				warning: {
					backgroundColor: BrandColor.neutral_400,
				},
			},
		},
	},
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row',
		paddingHorizontal: spacing.xl,
		gap: spacing.xs,
	},
	buttonTitle: {
		textTransform: 'uppercase',
		variants: {
			type: {
				primary: {
					color: BrandColor.black,
				},
				secondary: {
					color: BrandColor.white,
				},
				warning: {
					color: BrandColor.white,
				},
			},
		},
	},
	buttonTitleDisabled: {
		variants: {
			type: {
				primary: { color: BrandColor.neutral_300 },
				secondary: { color: BrandColor.neutral_400 },
				warning: { color: BrandColor.neutral_300 },
			},
		},
	},
}));
